<template>
  <base-modal v-model:open="isActive" size="larger" hide-footer>
    <template v-slot:button>
      <button
        @click.prevent="toggle"
        class="btn btn-sm ml-2"
      >
        Saiba mais
      </button>
    </template>

    <div class="card-body pt-4 flex flex-col justify-between bg-black">
      <div
        class="absolute top-2 right-4 cursor-pointer"
        @click.prevent="toggle"
      >
        <i class="fas fa-times" style="margin: 0 !important"></i>
      </div>
      <div class="flex justify-center items-center h-full text-white">
        <div class="text-center">
          <h1 class="text-6xl text-orange-500">Manycontent para sempre</h1>
          <p class="text-orange-500 text-2xl py-8">Nossa maior promoção nos últimos 7 anos</p>
          <p class="text-white w-3/4 mx-auto">
            Não deixe essa oportunidade passar: Pague uma única vez e tenha para sempre a única IA que descobre o que funciona para as suas redes sociais, cria seus conteúdos e agenda automaticamente.
            <br>
          </p>
          <p class="text-white w-3/4 mx-auto my-4">E ainda terá acesso a <strong class="text-orange-500">todas as novas funcionalidades</strong>, para sempre!</p>
          <p class="text-white w-3/4 mx-auto my-4">Oferta válida até 31/10/24</p>
          <button
            @click.prevent="ctaClick"
            class="btn btn-lg mt-8 mx-auto"
            :disabled="isSending"
          >
            Falar com especialista
          </button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import BaseModal from '../base/BaseModal.vue';
import localforage from 'localforage';
import { currentUser } from '@/composables/user/currentUser';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import axios from "axios"

const serviceEndpoint = 'https://us-central1-many-payment-55e67.cloudfunctions.net/manyPayments'

const MODAL_OPEN_KEY = 'modal_opened_today';

function parsePhoneNumber(phone: string) {
  // Usa a libphonenumber para parsear o número
  const parsedPhone = parsePhoneNumberFromString(phone.replace(/\D/g, ''));

  if (!parsedPhone) {
      throw new Error('Número de telefone inválido');
  }

  // Extrai o código do país
  const countryCode = parsedPhone.countryCallingCode;

  // O número nacional completo (sem o código do país)
  const nationalNumber = parsedPhone.nationalNumber;

  // Dependendo do país, o comprimento do código de área pode variar.
  // Vamos inferir o código de área para alguns casos conhecidos. 
  // Para os EUA, o código de área geralmente tem 3 dígitos.
  let areaCode = '';
  let phoneNumber = '';

  if (parsedPhone.country === 'US' || parsedPhone.country === 'CA') {
      // Nos EUA e no Canadá, o código de área tem 3 dígitos
      areaCode = nationalNumber.slice(0, 3);
      phoneNumber = nationalNumber.slice(3);
  } else if (parsedPhone.country === 'BR') {
      // No Brasil, o código de área tem 2 dígitos
      areaCode = nationalNumber.slice(0, 2);
      phoneNumber = nationalNumber.slice(2);
  } else {
      phoneNumber = nationalNumber
  }

  return {
      countryCode,
      areaCode,
      phoneNumber
  };
}

export default defineComponent({
  components: { BaseModal },

  setup () {
    const isSending = ref(false);
    const { user } = currentUser()

    const isActive = ref(false);

    const toggle = () => {
      isActive.value = !isActive.value;
    };

    const isSameDay = (storedDate: string) => {
      const today = new Date().toISOString().split('T')[0];
      return storedDate === today;
    };

    const checkModalStatus = async (): Promise<boolean> => {
      try {
        const storedDate = await localforage.getItem<string>(MODAL_OPEN_KEY);

        if (!storedDate || !isSameDay(storedDate)) {
          return true; // O modal deve ser aberto
        }
      } catch (error) {
        console.error('Erro ao verificar o status do modal:', error);
      }
      return false; // O modal não deve ser aberto
    }

    const openModalIfNeeded = async () => {
      const shouldOpen = await checkModalStatus();
      if (shouldOpen) {
        isActive.value = true;
      }
    };

    const ctaClick = async () => {
      try {
        isSending.value = true;
        const phoneToObject = (phone: string) => {
          try {
            const parsedPhone = parsePhoneNumber(phone);
            return {
              countryCode: parsedPhone.countryCode,
              areaCode: parsedPhone.areaCode,
              number: parsedPhone.phoneNumber
            };
          } catch (error) {
            console.log(error)
            phone = phone.replace(/\D/g, '');
            return {
              countryCode: phone.slice(0, 2),
                areaCode: phone.slice(2, 4),
              number: phone.slice(4)
            };
          }
        }

        const name = user.value.name;
        const email = user.value.email;
        const phone = phoneToObject(user.value.billing?.customer?.phone);

        await axios.post(`${serviceEndpoint}/lead`, {
          name,
          email,
          phone,
          tag: 'lifetime',
        })
        
        toggle()
        alert("Obrigado! Entraremos em contato em breve.");
      } catch (error) {
        console.log(error)
        alert("Ops! Algo aconteceu. Não foi possível conectar com o especialista agora, você poderia nos chamar pelo whatsapp?");
      } finally {
        isSending.value = false;
      }
    };

    onMounted(() => {
      setTimeout(openModalIfNeeded, 5000);
    });

    watch(isActive, async (newValue) => {
      if (newValue) {
        await localforage.setItem(MODAL_OPEN_KEY, new Date().toISOString().split('T')[0]);
      }
    });

    return {
      isActive,
      isSending,
      toggle,
      ctaClick
    }
  }
})
</script>

<style scoped>
</style>