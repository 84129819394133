<template>
  <div
    class="relative overflow-hidden min-h-full"
    :class="breakpoinStyle"
  >
    <div class="fixed top-0 left-0 right-0 z-10">
      <div class="relative w-full h-full">
        <dashboard-header
          @toggle-sidebar="toggleSidebar"
          class="h-16"
        />
      </div>
    </div>
    <div class="relative mt-16 z-0">
      <div class="flex min-h-full overflow-hidden">

        <dashboard-sidebar
          v-show="isSidebarVisible"
          :visible="isSidebarOpen"
          @toggle-sidebar="toggleSidebar"
        />

        <div class="content relative w-full">
          <div
            class="flex justify-center items-center h-12 p-4 bg-yellow-200 text-yellow-800 border border-orange-300"
            v-if="locale === 'pt-br'"
          >
            Tenha a Manycontent para sempre!
            <MainAlertModal />
          </div>
          <div
            class="content relative w-full bg-gray-100"
            :class="{ 'full-wrapper': contentFullWrapper, 'not-full-wrapper': !contentFullWrapper }"
          >
            <slot/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useToggle } from '@/composables/useToggle'
import { useBreakpoints } from '@/composables/useBreakpoints'
import DashboardHeader from './navbar/DashboardHeader.vue'
import DashboardSidebar from './sidebar/DashboardSidebar.vue'
import MainAlertModal from './MainAlertModal.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    DashboardHeader,
    DashboardSidebar,
    MainAlertModal
  },
  setup () {
    const store = useStore()
    const { locale } = useI18n()

    const { breakpoints } = useBreakpoints()

    const {
      isActive: isSidebarOpen,
      toggle: toggleSidebar
    } = useToggle(false)

    const isSidebarVisible = computed(() => store.state.dashboard.isSidebarVisible)

    const contentFullWrapper = computed(() => store.state.dashboard.contentFullWrapper)

    const breakpoinStyle = computed(() => {
      return breakpoints.smaller('sm').value ? 'is-mobile' : 'is-not-mobile'
    })

    return {
      toggleSidebar,
      isSidebarOpen,
      isSidebarVisible,
      contentFullWrapper,
      breakpoinStyle,
      locale
    }
  }
})
</script>

<style scoped>
@layer components {
  .content {
    @apply overflow-auto;
    overflow-y: overlay;
    max-height: calc(100vh - 4rem);
    overscroll-behavior: none;
    touch-action: auto;
    overflow-anchor: none;
  
    min-height: calc(100vh - 4rem);
  }

  .not-full-wrapper {
    @apply p-4;
  }

  .full-wrapper {
    @apply p-0;
  }
}
</style>
